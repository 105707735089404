header {
  margin-top: 5rem;
  height: calc(80vh - 6rem);
  display: grid;
  place-items: center;
  background: var(--color-white);
  transition: var(--transition);
}

.header_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header_profile {
  width: 18rem;
  height: 22rem;
  border-radius: 4rem 4rem 2rem 2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.header_container p {
  width: 35rem;
  text-align: center;
  margin: 0.6rem 0 2rem;
}

.header_cta {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.header_socials {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header_socials a {
  width: 1.8rem;
  aspect-ratio: 1/1;
  background: var(--color-light);
  color: var(--color-black);
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-3);
}

.header_socials a:hover {
  background: var(--color-primary);
  color: var(--color-white);
  transform: translate(-0.5rem);
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    header {
        margin-top: 0;
        height: 100vh;
    }
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 600px) {
    .header_profile {
        width: 14rem;
        height: 19rem;
        max-width: 60%;
        max-height: 35vh;
    }

    .header_container p {
        width: 100%;
    }

    .header_cta {
        gap: 1rem;
    }

    .header_socials {
        display: none;
    }
}
