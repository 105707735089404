nav{
    width: 100vw;
    height: 80px;
    display: grid;
    place-items: center;
    background: var(--color-primary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    box-shadow: 0 16px 24px hsla(var(--primary-hue), 65%, 40%, 15%);
    transition: var(--transition);
}

.nav_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_logo {
    width: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
}

.nav_menu{
    display: flex;
    gap: 3.4rem;

}

#theme_icon{
background: transparent;
color: white;
font-size: 2rem;
cursor: pointer;
}

/* hid navbar on tablets and phones view */
@media screen and (max-width: 1024px) {
    .nav_menu{
        /* display: none; */
        font-size: small;
    }
}