* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --primary-hue: 270;
  --black-lightness: 15%;
  --dark-lightness: 44%;
  --light-lightness: 94%;
  --white-lightness: 100%;
  --color-primary: hsl(var(--primary-hue), 89%, 41%);
  --color-white: hsl(0, 5%, var(--white-lightness));
  --color-light: hsl(var(--primary-hue), 5%, var(--light-lightness));
  --color-black: hsl(var(--primary-hue), 5%, var(--black-lightness));
  --color-dark: hsl(var(--primary-hue), 5%, var(--dark-lightness));

  /* Container  */
  --container-width-lg: 76%;
  --container-width-md: 90%;

  --border-radius-1: 1.2rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 0.5rem;

  /* Set Transition  */
  --transition: all 500ms ease;
}

/* allow smooth scrolling  */
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  font-size: 0.9rem;
}

/* generic container class   */
.container {
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;
}

/* section class  */
section {
  padding: 10rem 0;
  background-color: var(--color-light);
  transition: var(--transition);
}

section:nth-child(even) {
  background-color: var(--color-white);
}

section > h2 {
  text-align: center;
}

section > p {
  text-align: center;
  margin: 0.5rem auto 4rem;
  width: 40%;
}

/* section styles  */
a {
  color: var(--color-white);
  transition: var(--transition);
}

/* heading style  */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  color: var(--color-black);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.95rem;
}

h6 {
  font-size: 0.8rem;
}

/* image style  */
img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* button style */
.btn {
  display: inline-block;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--color-light);
  color: var(--color-black);
  padding: 0.6rem 2.5rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-2);
  cursor: pointer;
}

.btn:hover {
  background-color: transparent;
  border-color: var(--color-light);
  transform: translateY(-0.5rem);
}

.btn.white {
  background: var(--color-white);
}

.btn.white:hover {
  background: transparent;
  border-color: var(--color-white);
}

.btn.primary {
  background: var(--color-primary);
  color: white;
}

.btn.primary:hover {
  background: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.btn.sm {
  padding: 0.5rem 1.2rem;
}


/* MEDIA QUERIES tablet size */
@media screen and (max-width: 1024px) {
    body {
        font-size: 1rem;
    }
    
    .container {
        width: var(--container-width-md);
    }

    section {
        padding: 8rem 0;
    }

    section > p {
        width: 72%;
        margin-bottom: 3rem;
    }

    h1 {
        font-size: 2rem;
    }
}

/* MEDIA QUERIES phone size */
@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.4rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.4rem;
    }

    h4 {
        font-size: 1rem;
    }

    .btn {
        padding: 0.6rem 1.5rem;
    }

    section > p {
        width: var(--container-width-md);
    }
}
